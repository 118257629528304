<div>
<div class="about-us-section container-fluid row justify-content-around">
    <!-- <div class="card-conatiner justify-content-start"> -->
        <div class="about-data-card col-12 col-md-5">
            <div class="who-are-we-header">sivan infotech</div>
            <div class="who-are-we">Sivan Info Tech is an IT organization registered under the Ministry of corporate affairs as Limited Liability partenership with over two decades of experience in top notch MNC companies</div>
        </div>
    <!-- </div> -->
    <!-- <div class="card-conatiner justify-content-end"> -->
        <div class="about-data-card col-12 col-md-6">
            <div class="our-services-header">services</div>
            <div class="service">1. Offering Cloud Computing Training to freshers, laterals and conducting internship programs</div>
            <div class="service">2. Products compatibility with cloud</div>
        </div>
    <!-- </div>   -->
   

        <div class="image-title">Designated Directors</div>
        <div class="image-container">
            <div class="justify-content-between images row ">
                <div class="image col-12 col-md-3">
                    <img class="styled-image" alt="Image Description" src="../../assets/images/pexels-thegiansepillo-7228465.jpg">
                    <div class="image-description">Kanagavalli N</div>
                </div>
                <div class="image  col-12 col-md-3">
                    <img class="styled-image" alt="Image Description" src="../../assets/images/pexels-dapo-abideen-4924538 (1).jpg">
                    <div class="image-description">Gunashekaran G</div>
                </div>
                <div class="registered-info col-12 col-md-4">
                    <div>
                        LLP Identification Number - ACA-1823<br>
                        Pan of LLP - AEYFS 4555G<br>
                        Tan of LLP - CHES 69130F<br>
                        Email - info@sitcloud.in<br>
                        Registered Address - <br>
                        No 9, Madhan Square,<br>
                        Guduvanchery, Chennai - 603202
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="testimonials-title text-center align-items-center">testimonials </div>
    <div class="row testimonials h-50 container-fluid justify-content-around ">
        <div class="my-card col-12 col-md-3">
            <img class="icon-image" alt="Image Description" src="../../assets/images/heIcon.png">
            <div class="teste-name">Manikanta</div>
            <div class="teste-content">I joined this institute to take training for AWS Solution Architect – professional level. My all over experience is very good. The knowledge given by the trainers are very helpful to me & during training they solve thought related the topic. Thank you.</div>
        </div>
        <div class="my-card col-12 col-md-3">
            <img class="icon-image" alt="Image Description" src="../../assets/images/heIcon.png">
            <div class="teste-name">Vignesh</div>
            <div class="teste-content">I joined this institute to take training for AWS Solution Architect – professional level. My all over experience is very good. The knowledge given by the trainers are very helpful to me & during training they solve thought related the topic. Thank you.</div>
        </div>
        <div class="my-card col-12 col-md-3">
            <img class="icon-image" alt="Image Description" src="../../assets/images/sheIcon.png">
            <div class="teste-name">Indrani</div>
            <div class="teste-content">Provided in depth knowledge about the topics. Shown most of the topics practically which help in visualizing the topic. Providing training video is a plus.</div>
        </div>
        <div class="my-card col-12 col-md-3">
            <img class="icon-image" alt="Image Description" src="../../assets/images/heIcon.png">
            <div class="teste-name">Praveen</div>
            <div class="teste-content">Training was excellent with good interaction. Knowledge sharing is good. Recording facility is excellent for revising. Course was practically and informative. Trainer Kavitha is enthusiastic and aware of what she is explaining. The course helped to build confidence, Valuable experiences, and learning. She mapped all info’s to real-time world, where it’s helped a lot for me to understand easily.</div>
        </div>
    </div>
</div>