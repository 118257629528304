

<div>
  <div class="footer row align-items-start">
    <div class="slope-bg1"></div>
    <a class="col-12 col-md-1" href="">
      <img src="../../assets/images/footerlogo.png" alt="" class="logo" />
    </a>
    <div class="content col-12 col-md-3">
      Sivan Info Tech is a leading Training and Placement company managed by IT veterans with more than a decade of experience in leading MNC companies.
      <!-- <div><a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
        <img src="../../assets/images/youtube.png" alt="YouTube Logo" class="small-image" />
      </a><a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
        <img src="../../assets/images/insta.png" alt="YouTube Logo" class="small-image" />
      </a><a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
        <img src="../../assets/images/facebook.png" alt="YouTube Logo" class="small-image" />
      </a><a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
        <img src="../../assets/images/linkedin.png" alt="YouTube Logo" class="small-image" />
      </a></div> -->
      <!-- <div><a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
        <img src="../../assets/images/insta.png" alt="YouTube Logo" class="small-image" />
      </a></div> -->
      <!-- <div><a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
        <img src="../../assets/images/youtube.png" alt="YouTube Logo" class="small-image" />
      </a></div> -->
    </div>
    <div class="Usefullinks col-10 col-md-2">
      <div class="head">Useful Links</div>
      <div class="details">
        <a href="/home" class="links">Home</a>
        <a href="" class="links">Contact Us</a>
        <a href="/terms" class="links">Terms and Conditions</a>
        
        <a href="/refund" class="links">Refund Policy</a>
      </div>
    </div>
    <div class="contactdetails col-12 col-md-3">
      <div class="head">Contact Details</div>
      <a href="https://maps.app.goo.gl/tffybvxnKByA6aDg9?g_st=ic"  target="_blank" class="loclinks">
        <!-- <div class="loc"> -->
          <div class="row">
            <div class="col-1">
              <mat-icon>location_on</mat-icon>
            </div>
            <span class="contactdetail col-10">
              Sivan Info Tech First floor, 9, Madhan Square, Neelamangalam, Guduvancheri, Chennai<br>
              TamilNadu, India - 603202
            </span>
          </div>
        <!-- </div> -->
      </a>
      <a href="tel: 91 63813 92637" target="_blank"class="locationlinks">
        <div class="row">
          <div class="col-1">
            <mat-icon>phone_in_talk</mat-icon>
          </div>
          <span class="contactdetail col-10">+91 6381392637</span>
        </div>
      </a>
      <a class="locationlinks" href="mailto: info@sitcloud.in" target="_blank">
        <div class="row">
          <div class="col-1">
            <mat-icon>email</mat-icon>
          </div>
          <span class="contactdetail col-10">info@sitcloud.in</span>
        </div>
      </a>
      <!-- <div class="col-12 col-md-1">
        <a href="https://www.youtube.com/results?search_query=sivaninfotech+llp" target="_blank">
          <img src="../../assets/images/youtube.png" alt="YouTube Logo" class="small-image" />
        </a>
      </div> -->
    </div>
  </div>
</div>