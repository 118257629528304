<table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Position Column -->
  <!-- <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef> No. </th>
      <td mat-cell *matCellDef="let element"> {{element.position}} </td>
    </ng-container> -->

  <!-- Name Column -->
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef> Name </th>
    <td mat-cell *matCellDef="let element"> <span>{{element.firstName}} {{element.lastName}} </span></td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef> Email </th>
    <td mat-cell *matCellDef="let element"> <span>{{element.email}} </span></td>
  </ng-container>

  <!-- Symbol Column -->
  <ng-container matColumnDef="role">
    <th mat-header-cell *matHeaderCellDef> Role </th>
    <td mat-cell *matCellDef="let element"><span> {{element.role}}</span> </td>
  </ng-container>
  <!-- Symbol Column -->
  <ng-container matColumnDef="verified">
    <th mat-header-cell *matHeaderCellDef> Verified </th>
    <td mat-cell *matCellDef="let element">
      <div *ngIf="element.verified == true"><span>
          <img src="https://www.freepnglogos.com/uploads/tick-png/image-tick-mark-icon-png-good-luck-charlie-wiki-2.png"
            width="28px" alt="image tick mark " />
        </span>
      </div>
      <div *ngIf="element.verified == false"> <span>
          <img src="https://www.freeiconspng.com/uploads/red-cross-png-33.png" width="29px" alt="red cross png" />
        </span>
      </div>
    </td>
  </ng-container>

  <ng-container matColumnDef="enrolled">
    <th mat-header-cell *matHeaderCellDef> Enrolled </th>
    <td mat-cell *matCellDef="let element">

      <div *ngIf="element.enrollments?.length>0"><span>
          <img src="https://www.freepnglogos.com/uploads/tick-png/image-tick-mark-icon-png-good-luck-charlie-wiki-2.png"
            width="28px" alt="image tick mark icon png good luck charlie wiki" />
        </span></div>
      <div *ngIf="!element.enrollments || element.enrollments?.length<=0"><span>
          <img src="https://www.freeiconspng.com/uploads/red-cross-png-33.png" width="29px" alt="red cross png" />
        </span>
      </div>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="getRecord(row)"></tr>
</table>