<div>
    <div class="container1">
        <h1>Return and Refund Policy </h1>
        <br>
        <h3>Refund Policy</h3>
        <p>Refunds are processed in accordance with the terms and conditions outlined on the invoice accompanying the services provided. Students/Interns are encouraged to review the refund section of their invoice for specific eligibility criteria, refund amounts, and procedures.
        </p>
        <h3>Cancellation and Refunds</h3>
        <p> Students/Interns may request cancellation of services as per the terms outlined on the invoice. Refund eligibility and amounts are determined based on the specific terms and conditions stated on the invoice.

            <h3>Refund Processing</h3>
        <p>Upon receiving a valid refund request as per the invoice terms, we will review and process the refund accordingly. Refunds, if approved, will be processed within 3 working days of receiving the request, in accordance with the instructions provided on the invoice.
        </p>
        <h3>
            NoShow Policy</h3>
        <p>Students/Interns failing to adhere to scheduled appointments or utilizing reserved service hours without prior cancellation may be subject to fees or charges as outlined on the invoice.
                <br>
            No refunds will be provided for missed appointments or unused service hours due to client noshows or failure to provide sufficient notice of cancellation.
           
    </div>
</div>



