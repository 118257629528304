<div>
    <div class="container1">
        <h1>Terms and Conditions </h1>
        <h3>Introduction</h3>
        <p> By accessing or using our website and services, you agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you may not access the website or use our services.
        </p>
        <h3>Use of Website and Services</h3>
        <p> Our website and services are intended for individuals seeking cloud computing training. You may use the website and services only for lawful purposes and in accordance with these terms and conditions.</p>
        <h3>Intellectual Property Rights</h3>
        <p>The content, materials, and resources provided on our website are protected by copyright and other intellectual property laws. You agree not to copy, distribute, or modify any content from our website without our prior written consent.
        </p>
        <h3>
            User Accounts</h3>
        <p>In order to access certain features of our website or services, you may be required to create a user account. You are responsible for maintaining the confidentiality of your account and password and for restricting access to your account.</p>
        <h3>Payment and Fees</h3>
        <p>Payment for our training programs or services is required in advance unless otherwise specified. All fees are nonrefundable except as expressly provided in our refund policy.</p>
        <h3>Limitation of Liability</h3>
        <p>  We do not guarantee that our website or services will be errorfree, uninterrupted, or secure. In no event shall we be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of our website or services.</p>
        <h3>
            Indemnification</h3>
        <p>You agree to indemnify, defend, and hold harmless our company, its officers, directors, employees, agents, and affiliates from and against any and all claims, damages, losses, liabilities, costs, or expenses arising out of or related to your use of our website or services.</p>
        <h3>Changes to Terms and Conditions</h3>
        <p>We reserve the right to modify or revise these terms and conditions at any time without notice. By continuing to use our website or services after any such changes, you agree to be bound by the revised terms and conditions.</p>

    </div>
</div>