<!-- <h1  class="regform">Cloud-acclerator</h1> -->

<!-- <title>sivaninfotech Cloud Accelerator</title> -->


<div class="container1">
   <div class="container">
    <div class="heading">
      <h1 class="cd">SIVAN INFO TECH</h1>
      <h5 class="para">Experience speed, predictability and reduced risk<br> with our  cloud transformation</h5>
      <!-- <img src="../../assets/images/aws-2.png" alt="Image">  -->
     </div>
     
    <br><br>
    <div class="buttons"> 
      <!-- <a href="#">Watch the demo (05:11)</a> -->
      <a href="mailto:info@sitcloud.in">Contact us</a>
      
    </div>
    
  </div>

  </div>
 


  
<!-- 
<div class="boxi"> 
  <div>
    
    <p class="boxip">Team of skilled AWS Cloud experts, helping organizations migrate to Cloud.</p>
  </div>
  <div><p class="boxip">We help you to become Cloud ready and migrate with speed for better outcomes.</p></div>
  <div><p class="boxip"> you're looking for Cost Optimization using right technology, we might be a perfect find.</p></div>
</div> -->
<!-- <div class="product_contain">
  <h1 class="product">Our Products</h1>
</div>



  <div class="Terragen">
   <h2 class="Ter">Teragen2 allows you to generate Infrastructure-as-Code outputs from your existing resources within your AWS account.
    By making the relevant calls using the AWS JavaScript SDK, Teragen2 will scan across your infrastructure and present you with the list of 
    resources for you to choose which to generate outputs for.</h2>

    
  

  

  </div>
  <div class="div"><h4 class="Ter">Keep checking our page for exciting new products and updates!</h4></div>   -->
  
  <div class="container3">
    <div class="column">
      <div class="division"><img src="../../assets/images/expert.jpg" alt="Image 1" style="max-width: 100%;">
        <p class="boxip">Team of skilled AWS Cloud experts, helping organizations migrate to Cloud.</p>
      </div>
      
    </div>
    <div class="column">
      <div class="division"><img src="../../assets/images/cloudservice.jpg" alt="Image 1" style="max-width: 100%;">
        <p class="boxip">We help you to become Cloud ready and migrate with speed for better outcomes.</p>
      </div>
    
    </div>
    <div class="column">
      <div class="division"><img src="../../assets/images/priceoptimaization.jpg" alt="Image 1" style="max-width: 100%;">
        <p class="boxip"> you're looking for Cost Optimization using right technology, we might be a perfect find.</p></div>
     
    </div>
  </div>

  <div class="product_contain">
  <h1 class="product">Our Products</h1>
</div>



  <!-- <div class="Terragen">
   <h2 class="Ter">Teragen2 allows you to generate Infrastructure-as-Code outputs from your existing resources within your AWS account.
    By making the relevant calls using the AWS JavaScript SDK, Teragen2 will scan across your infrastructure and present you with the list of 
    resources for you to choose which to generate outputs for.</h2>

    
  

  

  </div> -->
  <div class="containers">
    <!-- Left Division with Heading and Paragraph -->
    <div class="left-division">
      <h2>Terragen</h2>
      <p class="parabold">Teragen2 allows you to generate Infrastructure-as-Code outputs from your existing resources within your AWS account.
        By making the relevant calls using the AWS JavaScript SDK, Teragen2 will scan across your infrastructure and present you with the list of 
        resources for you to choose which to generate outputs for.</p>
    </div>
    
    <!-- Right Division with Image -->
    <div class="right-division">
      <img src="../../assets/images/Cloudbasedmodel.jpg" alt="Image">
    </div>
  </div>



  <div >
    <h4 class="Ter">Keep checking our page for exciting new products and updates!</h4>
  </div>  

<section class="social-media">
  <div class="conatiner text-center">
    <p class="paragraph">FIND US ON SOCIAL MEDIA</p>
    <div class="social-icons">
      <a href=""><img src="../../assets/images/whatsapp-icon.png" alt=""></a>
      <a href="www.youtube.com/@SivaninfotechLLP"><img src="../../assets/images/facebook-icon.png"alt=""></a>
      <a href="www.instagram.com/sivan_info_tech"><img src="../../assets/images/instagram-icon.png" alt=""></a>
      <a href=""><img src="../../assets/images/-icon.png" alt=""></a>
      <a href="linkedin.com/in/kanagavalli-narasimhan-173ba3263"><img src="../../assets/images/linkedin-icon.png"alt=""></a>
      <a href=""><img src="../../assets/images/twitter-icon.png" alt=""></a>
    </div>
  </div>
</section>







